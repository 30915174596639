import React, { useEffect, useCallback, useState, useMemo } from 'react'
import Theme from '@refera/ui-core'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Typography } from '@refera/ui-web'
import { FilterSearch as FilterIcon, DocumentDownload } from '@refera/ui-icons'
import { Dialog, DialogActions, DialogContent, DialogContentText, Grid } from '@material-ui/core'

import { getCities } from '_modules/utils/actions'
import { getUserAgents } from '_modules/responsible/actions'
import { getUser, updateUserState } from '_modules/authentication/actions'
import { getAgencies, getAgencyResponsible } from '_modules/agency/actions'
import {
  getStepStatusFilter,
  getServiceOrders,
  setDefaultServiceOrderFilter,
  updateServiceOrderFilter,
  getGenericParameters,
} from '_modules/service-orders/actions'

import {
  getServiceOrderFilterSelector,
  getServiceOrderIsFilterDirtySelector,
  getServiceOrderLoadingSelector,
  getStepStatusFilterLoadingSelector,
  getStepStatusFilterSelector,
} from '_modules/service-orders/selectors'
import { userSelector } from '_modules/authentication/selectors'

import useToggle from '_hooks/use-toggle'
import useRequest from '_/hooks/use-request'
import useRolePermission from '_hooks/use-role-permission'

import { AGENCY_TYPES_OPTIONS } from '_utils/constants'
import { GENERIC_PARAMETERS } from '_utils/constants/service-order'

import ButtonComponent from '_components/button'
import RatingModal, { WARNING_MODAL } from '_components/modal/rating-modal'
import Loading from '_components/loading'
import {
  ServiceOrderMainTable,
  ServiceOrderMainViewFilter,
  FILTER_OPTIONS,
} from '_components/service-order'

import useStyles from './styles'
import { downloadReport } from '_/services/agency'

// TODO: Rename component and folder once this view is ready to Production. Also, remove the older view/components.
const ManagementServiceOrder = () => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const stepStatusFilter = useSelector(getStepStatusFilterSelector)
  const isStepStatusFilterLoading = useSelector(getStepStatusFilterLoadingSelector)
  const user = useSelector(userSelector)
  const filters = useSelector(getServiceOrderFilterSelector)
  const isFilterDirty = useSelector(getServiceOrderIsFilterDirtySelector)
  const [firstPageLoad, setFirstPageLoad] = useState(true)
  const { isAdmin, isIntermediary } = useRolePermission()

  const [ratingModal, setRatingModal] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  })

  const [page, setPage] = useState(filters?.page || 0)
  const [pageSize, setPageSize] = useState(filters?.pageSize || 10)
  const [orderBy, setOrderBy] = useState(filters?.orderBy || '')
  const [isFilterOpen, handleFilterOpen] = useToggle()

  const [isGetUserLoading] = useRequest(getUser())

  const isGetServiceOrdersLoading = useSelector(getServiceOrderLoadingSelector)

  const chipColor = useMemo(
    () => (user.viewOnlyMyCompanyTickets ? styles.chipEnabled : styles.chipDisabled),
    [user.viewOnlyMyCompanyTickets]
  )
  const isLoading = useMemo(
    () => isGetServiceOrdersLoading || isGetUserLoading,
    [isGetServiceOrdersLoading, isGetUserLoading]
  )

  const agencyDefaultValue = useMemo(() => {
    if (isAdmin) {
      return AGENCY_TYPES_OPTIONS[0]?.value
    }
    return ''
  }, [isAdmin])

  const responsibleDefaultValue = useMemo(
    () => (isIntermediary ? user?.id : ''),
    [filters?.[FILTER_OPTIONS.RESPONSIBLE]]
  )

  const defaultValues = useMemo(
    () => ({
      [FILTER_OPTIONS.AGENCY]: filters?.[FILTER_OPTIONS.AGENCY] || agencyDefaultValue,
      [FILTER_OPTIONS.CLASSIFICATION]: filters?.[FILTER_OPTIONS.CLASSIFICATION] || '',
      [FILTER_OPTIONS.CITY]: filters?.[FILTER_OPTIONS.CITY] || '',
      [FILTER_OPTIONS.FULL_ADDRESS]: filters?.[FILTER_OPTIONS.FULL_ADDRESS] || '',
      [FILTER_OPTIONS.MESSAGE]: filters?.[FILTER_OPTIONS.MESSAGE] || '',
      [FILTER_OPTIONS.SERVICEORDER_STATUS]: filters?.[FILTER_OPTIONS.SERVICEORDER_STATUS] || '',
      [FILTER_OPTIONS.PROPERTY_CODE]: filters?.[FILTER_OPTIONS.PROPERTY_CODE] || '',
      [FILTER_OPTIONS.REQUESTER_DATA]: filters?.[FILTER_OPTIONS.REQUESTER_DATA] || '',
      [FILTER_OPTIONS.RESPONSIBLE]: responsibleDefaultValue,
      [FILTER_OPTIONS.SERVICE_ORDER_ID]: filters?.[FILTER_OPTIONS.SERVICE_ORDER_ID] || '',
      [FILTER_OPTIONS.SITUATION]: filters?.[FILTER_OPTIONS.SITUATION] || '',
      [FILTER_OPTIONS.STEP_STATUS]: filters?.[FILTER_OPTIONS.STEP_STATUS] || '',
      [FILTER_OPTIONS.TRADESMAN]: filters?.[FILTER_OPTIONS.TRADESMAN] || '',
    }),
    [filters, agencyDefaultValue, responsibleDefaultValue]
  )

  useEffect(() => {
    if (user?.getRoles && firstPageLoad) {
      if (!isFilterDirty) {
        dispatch(
          setDefaultServiceOrderFilter({
            isAdmin,
            isIntermediary,
            userId: user?.id,
            agency: AGENCY_TYPES_OPTIONS[0]?.value,
          })
        )
      }

      setFirstPageLoad(false)
    }
  }, [user?.getRoles, firstPageLoad, isAdmin, isIntermediary])

  useEffect(() => {
    if (!isGetServiceOrdersLoading && !firstPageLoad) {
      dispatch(
        getServiceOrders({
          ...filters,
          see_only_my_company_service_order: user?.viewOnlyMyCompanyTickets,
          page: page + 1,
          pageSize,
          order: orderBy,
        })
      )
      dispatch(updateServiceOrderFilter({ ...filters, page, pageSize, order: orderBy }))
    }
  }, [firstPageLoad, orderBy, page, pageSize, user?.getRoles])

  useEffect(() => {
    dispatch(getAgencies())
    dispatch(getCities())
    user?.agency && dispatch(getAgencyResponsible(user.agency))
    dispatch(getUserAgents())
    dispatch(
      getGenericParameters({
        name: GENERIC_PARAMETERS.PAUSE_SERVICE_ORDER,
      })
    )
    dispatch(
      getGenericParameters({
        name: GENERIC_PARAMETERS.DELAYED,
      })
    )
    dispatch(
      getGenericParameters({
        name: GENERIC_PARAMETERS.NEW_SERVICE_ORDER_FORMS,
        agencyId: user?.agency,
      })
    )
  }, [dispatch, user])

  useEffect(() => {
    if (!stepStatusFilter && !isStepStatusFilterLoading) {
      dispatch(getStepStatusFilter({ isFilterActive: user.viewOnlyMyCompanyTickets }))
    }
  }, [dispatch, stepStatusFilter, isStepStatusFilterLoading, user.viewOnlyMyCompanyTickets])

  const handleOrderBy = useCallback(orderObj => {
    const order = orderObj[0]

    if (!order) {
      setOrderBy('createdAt')
      return
    }

    const { field, sort } = order

    setOrderBy(`${sort === 'desc' ? '-' : ''}${field}`)
  }, [])

  const [modalConfirmDownloadIsOpen, setModalConfirmDownloadIsOpen] = useState(false)

  const handleConfirmDownloadReport = async () => {
    try {
      const response = await downloadReport()

      const responseUrl = await fetch(response.url)
      const blob = await responseUrl.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'download-report.xlsx'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    } catch (err) {
      setRatingModal({
        isOpen: true,
        title: 'Atenção!',
        subTitle:
          'Error, não foi possível fazer o download no momento, tente novamente mais tarde!',
        type: WARNING_MODAL,
      })
    } finally {
      setModalConfirmDownloadIsOpen(false)
    }
  }

  if (isLoading && firstPageLoad) {
    return <Loading />
  }

  const handleFilter = () => {
    const payload = {
      ...filters,
      see_only_my_company_service_order: !user.viewOnlyMyCompanyTickets,
    }

    dispatch(
      getServiceOrders({
        ...payload,
      })
    )
    dispatch(getStepStatusFilter({ isFilterActive: !user.viewOnlyMyCompanyTickets }))
    dispatch(updateUserState({ viewOnlyMyCompanyTickets: !user.viewOnlyMyCompanyTickets }))
  }

  return (
    <Grid className={styles.container}>
      <ServiceOrderMainViewFilter
        handleFilterOpen={handleFilterOpen}
        open={isFilterOpen}
        defaultValues={defaultValues}
        setPage={setPage}
      />
      <Grid className={styles.header}>
        <Typography variant="h4" component="h1" className={styles.title}>
          Gestão de chamados
        </Typography>
        <Grid className={styles.headerButton}>
          {isIntermediary && (
            <Grid>
              <Button
                disabled={isLoading}
                variant="ghost"
                startIcon={<DocumentDownload color={Theme.Colors.Primary.Base} />}
                onClick={() => setModalConfirmDownloadIsOpen(true)}
              >
                Baixar relatório
              </Button>

              <button
                type="button"
                className={`${styles.chip} ${chipColor}`}
                onClick={() => handleFilter()}
              >
                Ver somente meus chamados da empresa
              </button>
            </Grid>
          )}
          <Button
            disabled={isLoading}
            variant="ghost"
            startIcon={<FilterIcon color={Theme.Colors.Primary.Base} />}
            onClick={handleFilterOpen}
          >
            Filtros
          </Button>
        </Grid>
      </Grid>
      <Grid className={styles.tableContainer}>
        <Grid className={styles.mainTable}>
          <ServiceOrderMainTable
            onPageChange={setPage}
            page={page}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            onSortModelChange={handleOrderBy}
            defaultValues={defaultValues}
            user={user}
          />
        </Grid>
      </Grid>

      <Dialog
        open={modalConfirmDownloadIsOpen}
        onClose={() => setModalConfirmDownloadIsOpen(false)}
        BackdropProps={{ invisible: false }}
      >
        <DialogContent>
          <DialogContentText id="confirmDownloadReport">
            <strong className={styles.modalTextTitle}>
              Você tem certeza que deseja baixar o relatório de chamados?
            </strong>
            <p className={styles.modalText}>
              Este relatório contém todos os chamados, e é atualizado diariamente durante a
              madrugada.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonComponent onClick={() => setModalConfirmDownloadIsOpen(false)} variant="outlined">
            Não
          </ButtonComponent>
          <ButtonComponent
            onClick={handleConfirmDownloadReport}
            isLoading={isLoading}
            color="primary"
            variant="contained"
          >
            Sim
          </ButtonComponent>
        </DialogActions>
      </Dialog>

      <RatingModal ratingModal={ratingModal} setRatingModal={setRatingModal} />
    </Grid>
  )
}

export default ManagementServiceOrder
